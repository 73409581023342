import * as React from "react";
import sendForm from "core/services/api/sendForm";
import { Rewards as FormModel } from "core/models";
import {
    FormResponse,
    FormFieldset,
    ModuleWrapper,
    Loader,
    Button,
    Container,
    Title,
} from "view/components";
import ConfigContext from "view/context/ConfigContext";
import sendContactAnnex from "core/services/api/contactAnnex";
import {
    CampaignType,
    CampaignTypeEntity,
    RewardsFormFields,
} from "core/models/Rewards";
import { useLocation } from "react-router-dom";
import fetchCampaignTypes from "core/services/api/campaignTypes";
import useRecaptcha from "view/hooks/useRecaptcha";
import { rewardsFields } from "./RewardsFormFields";
import $ from "./Rewards.module.scss";

type MessageType =
    | "loading"
    | "success"
    | "fail"
    | "campaign-not-started"
    | "campaign-ended";

type Props = {
    title: FormModel["title"];
    internalCampaignName: FormModel["internalCampaignName"];
    text?: FormModel["text"];
    campaignType: FormModel["campaignType"];
    campaignStartDate: FormModel["campaignStartDate"];
    campaignEndDate?: FormModel["campaignEndDate"];
    campaignTermsLink: FormModel["campaignTermsLink"];
    descriptionPlaceholderText?: FormModel["descriptionPlaceholderText"];
    showDescriptionField?: FormModel["showDescriptionField"];
    showPhoneNumberField?: FormModel["showPhoneNumberField"];
    postalCodeRequired?: FormModel["postalCodeRequired"];
    showEanCodeField?: FormModel["showEanCodeField"];
    showAddressField?: FormModel["showAddressField"];
    eanCodeRequired?: FormModel["eanCodeRequired"];
    descriptionRequired?: FormModel["descriptionRequired"];
    phoneNumberRequired?: FormModel["phoneNumberRequired"];
    campaignNotStartedText?: FormModel["campaignNotStartedText"];
    campaignEndedText?: FormModel["campaignEndedText"];
    campaignSubmittedText?: FormModel["campaignSubmittedText"];
    contactServiceSubscriptionKey?: FormModel["contactServiceSubscriptionKey"];
};

function mapFields(fields: RewardsFormFields[]) {
    return fields.map((field) => {
        return {
            name: field.name,
            value:
                (field.type === "select" || field.type === "radio") &&
                field.options
                    ? field.options[0].value
                    : "",
        };
    });
}

function createInitialState(fieldset: any) {
    // Get all field ids and when a field is a select, use the first option as default value
    const nameValuePairFields = mapFields(fieldset.fields);
    const state: any = {};

    nameValuePairFields.forEach((obj: any) => {
        state[obj.name] = obj.value;
    });
    return state;
}

async function send(
    apiUrl: string,
    subscriptionKey: string | undefined,
    form: any,
) {
    const body = form;
    const response = await sendForm(
        apiUrl,
        subscriptionKey,
        body,
        "CustomersCampaign",
    );
    return response;
}

async function sendUploadedFiles({
    apiUrl,
    subscriptionKey,
    files,
    contactFormId,
    setMessage,
}: any) {
    const filesArray: File[] = [];
    let i;

    for (i = 0; i < files.length; i += 1) {
        filesArray.push(files.item(i));
    }

    const response = await sendContactAnnex({
        apiUrl,
        subscriptionKey,
        body: filesArray,
        contactFormId,
    });

    if (response && response.status === 200) {
        setMessage("success");
        return response;
    }

    return setMessage("fail");
}

const Rewards = (props: Props) => {
    const {
        title,
        internalCampaignName,
        text,
        campaignType,
        campaignStartDate,
        campaignEndDate,
        campaignTermsLink,
        descriptionPlaceholderText,
        showDescriptionField,
        showPhoneNumberField,
        showAddressField,
        showEanCodeField,
        eanCodeRequired,
        descriptionRequired,
        postalCodeRequired,
        phoneNumberRequired,
        campaignNotStartedText,
        campaignEndedText,
        campaignSubmittedText,
        contactServiceSubscriptionKey,
    } = props;
    const configContext = React.useContext(ConfigContext);
    const { verifyRecaptcha } = useRecaptcha(configContext);
    const location = useLocation();

    const fieldsets = rewardsFields(
        descriptionPlaceholderText,
        postalCodeRequired,
        eanCodeRequired,
        descriptionRequired,
        phoneNumberRequired,
    );

    const [isLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState<undefined | MessageType>(
        undefined,
    );

    const [rewardsInputFields, setRewardsInputFields] = React.useState<any>();
    const [rewardsForm, setRewardsForm] = React.useState<any>();
    const [files, setFiles] = React.useState<any>();

    const [campaignTypeForm, setCampaignTypeForm] =
        React.useState<CampaignType>();

    const formatData = (recaptchaCode: string) => {
        if (!campaignTypeForm) return {};

        let privacyPolicyChecked = false;
        const privacyPolicy = document.getElementById(
            "terms",
        ) as HTMLInputElement;
        if (privacyPolicy) {
            privacyPolicyChecked = privacyPolicy.checked;
        }
        const data = {
            CampaignType: {
                id: campaignTypeForm.id,
                name: campaignTypeForm.name,
                label: campaignTypeForm.label,
            },
            CampaignInfo: {
                campaignName: title,
                campaignNameInternal: internalCampaignName,
                campaignStartDate,
                campaignEndDate: campaignEndDate ?? "Geen afloopdatum",
            },
            Contact: {
                FirstName: rewardsForm.firstname,
                LastName: rewardsForm.lastname,
                Birthdate: rewardsForm.birthdate,
                Email: rewardsForm.email,
                EntryText: rewardsForm.description ?? "",
                PhoneNumber: rewardsForm.phone ?? "",
                EANCode: rewardsForm.eanCode ?? "",
                Street: rewardsForm.street ?? "",
                PostalCode: rewardsForm.postalcode,
                HouseNumber1: rewardsForm.housenumber ?? "",
                HouseNumber2: rewardsForm.housenumberaddition ?? "",
                City: rewardsForm.city ?? "",
                agreedWithPrivacyPolicy: privacyPolicyChecked,
                agreedWithCampaignTerms: true,
                // Check for development when portalKey is set to core
                SourceWebsite: `${
                    configContext.portalKey === "core"
                        ? "sourcy"
                        : configContext.portalKey.split("_").join("")
                }.nl/${location.pathname}`,
                SourceUrl: location.pathname + location.search,
            },
            recaptchaCode,
        };
        return data;
    };

    const handleInputChange = (
        e: any,
        name: string,
        form: any,
        setForm: (values: any) => void,
        files = null,
    ) => {
        const { value } = e.target;
        const newInputs = {
            ...form,
            [name]: value,
        };
        setForm(newInputs);

        if (files) {
            setFiles(files);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setMessage("loading");

        verifyRecaptcha()
            .then((recaptchaCode) => {
                const formattedData = formatData(recaptchaCode);

                send(
                    configContext.contactApiUrl,
                    contactServiceSubscriptionKey,
                    formattedData,
                )
                    .then((response) => {
                        if (files) {
                            const data = {
                                apiUrl: configContext.contactApiUrl,
                                subscriptionKey: contactServiceSubscriptionKey,
                                files,
                                contactFormId: response,
                                setMessage,
                            };
                            sendUploadedFiles(data);
                        } else setMessage("success");
                    })
                    .catch(() => setMessage("fail"));
            })
            .catch(() => setMessage("fail"));
    };

    React.useEffect(() => {
        const startDate = new Date(campaignStartDate);
        let endDate = campaignEndDate && new Date(campaignEndDate);

        // If no end date is set, set it to 31-12-9999 so the campaign will never end
        if (endDate === "" || endDate === undefined) {
            endDate = new Date("9999-12-31");
        }

        if (campaignStartDate === "" || campaignStartDate === undefined) {
            setMessage("campaign-not-started");
        }

        if (startDate && startDate > new Date()) {
            setMessage("campaign-not-started");
        }
        if (endDate && endDate < new Date()) {
            setMessage("campaign-ended");
        }
    }, [campaignEndDate, campaignStartDate]);

    const getDisabledFields = () => {
        const fieldsToHide: string[] = [];

        if (!showDescriptionField) {
            fieldsToHide.push("description");
        }

        if (!showPhoneNumberField) {
            fieldsToHide.push("phone");
        }

        if (!showEanCodeField) {
            fieldsToHide.push("eanCode");
        }

        return fieldsToHide;
    };

    const initiate = async () => {
        setIsLoading(true);

        await fetchCampaignTypes(
            configContext.contactApiUrl,
            contactServiceSubscriptionKey,
        )
            .then((response) => {
                // We need to filter rewards as type, as it's not a valid type for this form
                const typesData = response.data.filter(
                    (type: CampaignTypeEntity) => {
                        if (type.name !== campaignType) return false;
                        return true;
                    },
                );
                const types = typesData.map((type: CampaignTypeEntity) => {
                    return new CampaignType(type);
                });

                setCampaignTypeForm(types[0]);
            })
            .catch(() => {
                setIsLoading(false);
                setMessage("fail");
            });
        const generalFields = fieldsets.find(
            (fieldset) => fieldset.name === "Gegevens",
        );

        const addressFields = fieldsets.find(
            (fieldset) => fieldset.name === "Adresgegevens",
        );

        // Filter general fields to only show the fields that are not hidden
        if (generalFields) {
            if (showAddressField && addressFields) {
                generalFields.fields = generalFields.fields.concat(
                    addressFields.fields,
                );
            } else {
                // eslint-disable-next-line no-lonely-if
                if (addressFields) {
                    generalFields.fields = generalFields.fields.concat(
                        addressFields.fields.filter(
                            (field: any) => field.name === "postalcode",
                        ),
                    );
                }
            }

            generalFields.fields = generalFields.fields
                .filter(
                    (field: any) => !getDisabledFields().includes(field.name),
                )
                .sort((a: any, b: any) => a.order - b.order);
        }

        setRewardsInputFields(generalFields);
        setRewardsForm(createInitialState(generalFields));

        setIsLoading(false);
    };

    React.useEffect(() => {
        initiate();
    }, []);

    return (
        <ModuleWrapper moduleType="Rewards">
            <Container>
                <div className={$.container}>
                    {message === "campaign-not-started" && (
                        <FormResponse
                            title={
                                campaignNotStartedText ??
                                "De campagne is nog niet gestart!"
                            }
                            text="Kom op een later moment terug."
                            buttonText="Terug naar home"
                            link="/"
                        />
                    )}
                    {message === "campaign-ended" && (
                        <FormResponse
                            title={
                                campaignEndedText ?? "De campagne is voorbij!"
                            }
                            text="Helaas, je kunt niet meer meedoen."
                            buttonText="Terug naar home"
                            link="/"
                        />
                    )}
                    {!message && (
                        <>
                            {title && (
                                <Title className={$.title}>{title}</Title>
                            )}
                            {text && (
                                <div
                                    dangerouslySetInnerHTML={{ __html: text }}
                                    className={$.campaignText}
                                />
                            )}
                            <hr />
                            {isLoading && <Loader className={$.loader} />}
                            {!isLoading && (
                                <form
                                    onSubmit={handleSubmit}
                                    className={$.form}
                                >
                                    {rewardsInputFields && (
                                        <>
                                            <FormFieldset
                                                {...rewardsInputFields}
                                                form={rewardsForm}
                                                hideTitle
                                                handleChange={(
                                                    e,
                                                    name,
                                                    files,
                                                ) =>
                                                    handleInputChange(
                                                        e,
                                                        name,
                                                        rewardsForm,
                                                        setRewardsForm,
                                                        files,
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="terms"
                                                className={$.terms}
                                            >
                                                <input
                                                    type="checkbox"
                                                    value="terms-and-conditions"
                                                    id="terms"
                                                    className={$.termsInput}
                                                />
                                                <span
                                                    className={$.termsCheckbox}
                                                />
                                                <span className={$.termsLabel}>
                                                    Ik ga ermee akkoord dat
                                                    Vrumona B.V. mij op basis
                                                    van mijn voorkeuren en
                                                    gedrag via online media en
                                                    per e-mail van nieuws,
                                                    acties en aanbiedingen
                                                    voorziet over de merken en
                                                    evenementen van Vrumona B.V.
                                                    Ik kan mij op elk gewenst
                                                    moment hiervoor afmelden.
                                                    Zie meer info in de&nbsp;
                                                    <a
                                                        href={`https://www.vrumona.nl/pcs?utm_source=${
                                                            configContext.portalKey ===
                                                            "core"
                                                                ? "Sourcy"
                                                                : configContext.portalKey
                                                                      .split(
                                                                          "_",
                                                                      )
                                                                      .join(" ")
                                                        }&utm_campaign=mvp&utm_medium=website`}
                                                        className={$.link}
                                                    >
                                                        privacy voorwaarden
                                                    </a>
                                                    .
                                                </span>
                                            </label>
                                            <label
                                                htmlFor="actie-voorwaarden"
                                                className={$.terms}
                                            >
                                                <input
                                                    type="checkbox"
                                                    value="actie-voorwaarden"
                                                    id="actie-voorwaarden"
                                                    className={$.termsInput}
                                                    required
                                                />
                                                <span
                                                    className={$.termsCheckbox}
                                                />
                                                <span className={$.termsLabel}>
                                                    Ik ga akkoord met de{" "}
                                                    <a
                                                        href={campaignTermsLink}
                                                        className={$.link}
                                                    >
                                                        actievoorwaarden
                                                    </a>{" "}
                                                    van{" "}
                                                    <span
                                                        className={$.brandName}
                                                    >
                                                        {configContext.portalKey ===
                                                        "core"
                                                            ? "Sourcy"
                                                            : configContext.portalKey
                                                                  .split("_")
                                                                  .join(" ")}
                                                    </span>
                                                    .
                                                </span>
                                            </label>
                                        </>
                                    )}
                                    <Button
                                        className={$.sendButton}
                                        text="verstuur"
                                        type="submit"
                                        navy
                                    />
                                </form>
                            )}
                        </>
                    )}
                    {message === "fail" && (
                        <FormResponse
                            title="Er is iets fout gegaan"
                            text="Probeer het later nog eens."
                            onClickFunction={() => setMessage(undefined)}
                            buttonText="Probeer opnieuw"
                        />
                    )}
                    {message === "loading" && (
                        <div>
                            <Title className={$.titleSend}>
                                Aan het verzenden
                            </Title>
                            <Loader className={$.loader} />
                        </div>
                    )}
                    {message === "success" && (
                        <FormResponse
                            title="Bedankt voor je deelname!"
                            text={
                                campaignSubmittedText ??
                                "Bedankt dat je hebt meegedaan aan onze actie! Je ontvangt een e-mail met daarin een bevestiging van je deelname."
                            }
                            buttonText="Terug naar home"
                            link="/"
                        />
                    )}
                </div>
            </Container>
        </ModuleWrapper>
    );
};

export default Rewards;
