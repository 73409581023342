import { useCallback, useEffect, useState } from "react";
import { ConfigContextType } from "view/context/ConfigContext";

type Grecaptcha = typeof window.grecaptcha;

let recaptcha: Grecaptcha | null = null;
let recaptchaKey: string | null = null;
let recaptchaPromise: Promise<Grecaptcha> | null = null;

const getScriptGlobalObject = () => window.grecaptcha ?? null;
const loadScript = () => {
    const scriptId = "recaptcha-script";
    if (recaptchaPromise === null) {
        recaptchaPromise = new Promise<Grecaptcha>((resolve) => {
            const queue = () => {
                recaptcha = getScriptGlobalObject();
                if (recaptcha !== null) {
                    return resolve(recaptcha);
                }
                setTimeout(queue, 300);
                return undefined;
            };
            const scriptTag = document.getElementById(scriptId);
            if (scriptTag === null) {
                const script = document.createElement("script");
                script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
                script.id = scriptId;
                document.getElementsByTagName("head")[0].appendChild(script);
                queue();
            }
        });
    }

    return recaptchaPromise;
};

const useRecaptcha = (config: ConfigContextType | null) => {
    const [grecaptcha, setGrecaptcha] = useState(recaptcha);

    useEffect(() => {
        if (grecaptcha) return;

        if (config && config.recaptchaKey) {
            recaptchaKey = config.recaptchaKey;
            loadScript().then(setGrecaptcha);
        }
    }, []);

    const verifyRecaptcha = useCallback(() => {
        if (grecaptcha && recaptchaKey) {
            return grecaptcha.execute(recaptchaKey, {
                action: "submit",
            });
        }

        throw new Error("Recaptcha was not loaded or key is not set");
    }, [grecaptcha]);

    return {
        verifyRecaptcha,
    };
};

export default useRecaptcha;
