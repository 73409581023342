import * as React from "react";

export type ConfigContextType = {
    apiUrl: string;
    contactApiUrl: string;
    portalKey: "core" | "sourcy" | "royal_club";
    recaptchaKey: string;
};

const ConfigContext = React.createContext<ConfigContextType>({
    apiUrl: "",
    contactApiUrl: "",
    portalKey: "core",
    recaptchaKey: "",
});

export default ConfigContext;
